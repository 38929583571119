// i18next-extract-mark-ns-start markets-commercial

import { CAPABILITIES_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketCommercialPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Driving sustainable heavy duty vehicle transportation</h2>

						<p>
							In today's market with increasing emissions regulations aiming for a net zero future, the heavy-duty commercial transport industry is under pressure to reduce carbon emissions
							with such a large part of the global economy relying on the road transportation of goods and mass movement of people in buses and coaches.
							Commercial vehicle manufacturers now need to balance the optimisation of existing engines with lower emissions and increased fuel economy as well as introduce new non-carbon technology.
						</p>
						<p>
							The design challenge, using simulation, is to consider all the available the options across full electric,
							hybrid, hydrogen fuel cells, ammonia and hydrogen combustion as well as e-fuel viability.
						</p>
						<p>
							In the initial stages of development, physical prototype modelling is expensive, requiring virtual simulation tools to answer
							many of the 'What ifs' before committing to a design path.
							The propulsion system simulation is at the heart of truck and coach design where, predicting performance across a range of applications will be essential
							to select the correct propulsion system for the correct application going forward.
						</p>
						<p>
							At Realis Simulation we have extensive experience of using software to streamline the propulsion system design process –
							answering many of the ‘What ifs’ using computer simulation before Original Equipment Manufacturers (OEMs) and Tier 1 suppliers commit to expensive physical prototypes.
						</p>
						<p>
							Our software analyses the full heavy-duty vehicle system design from fuel intake, power generation, power transfer and exhaust emissions reduction.
							It simulates energy losses from friction, oil consumption and thermal heat transfer as well as component durability across various drive cycles using traditional,
							non-carbon e-fuels, hybrid, electric, hydrogen fuel cells and hydrogen combustion power units.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default MarketCommercialPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-commercial", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/commercial/Realis-Simulation_1152_Commercial.jpg" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { markets: { in: "Commercial" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 9
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;