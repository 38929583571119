import classnames from 'classnames';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { MouseEventHandler } from 'react';


const Button = ({
	to,
	children,
	type = '',
	onClick
}: {
	to: string;
	children: React.ReactNode;
	type?: '' | 'more' | 'download';
	onClick?: MouseEventHandler;
}) => {
	const isInternalLink = /^\/(?!\/)/.test(to);
	const componentClassNames = classnames('button', {
		[`button--${type}`]: !!type,
	});

	return isInternalLink ? (
		<Link to={to} className={componentClassNames} onClick={onClick}>
			{children}
		</Link>
	) : (
		<a href={to} className={componentClassNames} onClick={onClick}>
			{children}
		</a>
	);
};

export default Button;
